/** @file Provides a simple, native toast library for Modal. */

import { HTTPError, TimeoutError } from "ky";
import { toast } from "svelte-sonner";
import { v4 as uuidv4 } from "uuid";

import { default as ToastComponent } from "./ui/system/Toast.svelte";

export type Toast = {
  kind: "info" | "success" | "error";
  message: string;
  link?: { name: string; href: string };
};

export function makeToast(t: Toast, duration: number = 3000) {
  const id = uuidv4();
  toast(ToastComponent, {
    id,
    duration,
    unstyled: true,
    componentProps: {
      id,
      kind: t.kind,
      message: t.message,
      link: t.link,
    },
  });
}

export async function makeErrorToast(error: Error) {
  makeToast(
    {
      kind: "error",
      message: await normalizeError(error),
    },
    5000,
  );
}

/** Normalize an API error into a human-readable message. */
export async function normalizeError(error: Error): Promise<string> {
  let message = error.message;
  if (error instanceof TimeoutError) {
    message = "Network error: " + error.message;
  } else if (error instanceof HTTPError) {
    try {
      // Server errors have a JSON payload from Sanic. Network errors are not `HTTPError`.
      const payload: {
        description: string;
        status: number;
        message: string;
      } = await error.response.json();
      message = payload.description;
      if (payload.message && payload.message !== "None") {
        message += ": " + payload.message;
      }
    } catch (_) {
      // GoogleBot returns 499 for /api endpoints which don't have valid JSON responses.
      return message;
    }
  }
  return message;
}
