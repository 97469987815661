<script lang="ts">
  import { AlertTriangle, PartyPopper, XIcon } from "lucide-svelte";
  import { toast } from "svelte-sonner";

  import A from "$lib/ui/system/A.svelte";

  /** The ID of the toast from makeToast. */
  export let id: string;

  /** The kind of toast to display. */
  export let kind: "info" | "success" | "error" = "info";

  /** The message to display inside the toast. */
  export let message: string;

  /** An optional link to navigate to. */
  export let link: { name: string; href: string } | undefined;
</script>

<div class="toast-box">
  <div class="flex items-center">
    {#if kind === "success"}
      <div class="rounded-full bg-primary p-1.5 mr-3">
        <PartyPopper size={20} class="text-black" />
      </div>
    {:else if kind === "error"}
      <div class="rounded-full bg-error p-1.5 mr-3">
        <AlertTriangle size={20} class="text-black" />
      </div>
    {/if}

    <p>
      {message}
      {#if link}
        <A href={link.href}>{link.name}</A>
      {/if}
    </p>
  </div>

  <button
    class="p-2 text-zinc-400 border-zinc-400 hover:border-zinc-200 hover:text-white transition-colors"
    on:click={() => toast.dismiss(id)}
  >
    <XIcon size={20} />
  </button>
</div>

<style lang="postcss">
  .toast-box {
    @apply border border-zinc-700 bg-zinc-900 backdrop-blur-sm w-[380px];
    @apply px-3 py-3 rounded-lg flex pointer-events-auto justify-between;
    @apply text-sm;
  }
</style>
